
p{
	margin: 0px;
}

.message-list{
	overflow-y: scroll;
	height: 100%;
}
::-webkit-scrollbar{
	display: none;
}

.message-list-item{
	background-color:#F7F9FC;
	border: 1px solid#E1E6ED;
	height: 150px;
	padding: 20px;
	overflow: hidden;
	cursor: pointer;
}
.message-list-item.selected{
	background-color: white;
}

.email-subject{
	font-weight: 700;
	font-size: 15px;
	color: #25252B;
	width: 100%;
}

.message-content{
	padding: 30px 50px 30px 50px;
}
.message-content-email{
	font-weight: 700;
	font-size: 14px;
	color: #25252B;
}
.message-content-subject{
	font-weight: 700;
	font-size: 30px;
	color: #25252B;
}
.message-input{
	height: 105px !important;
	width: 100% !important;
	border-radius: 5px !important;
	border: 1px solid#EBECF0 !important;
	border-bottom: none !important;
  	-webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.TemplateWidth{
	margin: auto;
}

@media screen and (max-width: 500px) {
	.message-list-item{
		padding: 10px;
	}
	.message-content{
		padding: 10px;
	}
}