body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button{
  cursor:pointer;
}

@font-face {
  font-family: ProductSansBold;
  src: url(./Fonts/Product_Sans_Bold.ttf);
}

@font-face {
  font-family: ProductSansRegular;
  src: url(./Fonts/Product_Sans_Regular.ttf);
}

@font-face {
  font-family: ProductSansItalic;
  src: url(./Fonts/Product_Sans_Italic.ttf);
}

@supports (-ms-ime-align:auto) {
.features-img , .features-img-right , .features-img-left{
  width: 35%;
  height: 35%;
}
}

.underline-bold{
  text-decoration: underline;
  font-weight: bold;
}


.breadcrumb-div{
  display: flex;
  flex-direction: row;
}


.mobile-only{
  display: none;
}


.cpa-detail-breadcrumb{
  padding-top: 1px;
  margin-top: 1px;
  margin-left: 3px;
}

.cpa-detail-breadcrumb-2{
  padding-top: 3px;
  margin-top: 1px;
  margin-left: 3px;
}

.cpa-detail-breadcrumb-2:focus{
  padding-top: 3px;
  margin-top: 1px;
  margin-left: 3px;
  background: transparent
}

.double-disclaimer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
}

.d4-text2{
  font-weight: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  height:100%;
  padding: 0 20px;
  line-height: 1.8;
}

.d5-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  background-color: #F6F9FF;
  border: 2px dashed #4072EE;
  margin-top: 50px;
  margin-bottom: 10%;
  border-radius: 3px;
}

.d5-div2{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  background-color: #F6F9FF;
  border: 2px dashed #4072EE;
  margin-top: 50px;
  margin-bottom: 10%;
  border-radius: 3px;
  margin-left: 15px;
  width:1000px;
}


.d4-div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 5px;
  background-color: #ffffff;
  border: 2px dashed #4072EE;
  margin-top: 50px;
  margin-bottom: 10%;
  border-radius: 3px;
}

.d4-img{
  width: 160px;
  height:160px;
}

.d6-img{
  width: 140px;
  height:140px;
}

.d4-text{
font-weight: ProductSansRegular;
font-size: 16px;
color: #232735;
padding: 15px 50px 0px 15px;
}

.d4-link{
font-weight: ProductSansRegular;
font-size: 16px;
color: blue;
margin-top: 20px;

}



.d3-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
  padding-top: 250px;
}

.d3-img{
  width: 160px;
  height:160px;
}

.d3-text{
font-weight: ProductSansRegular;
font-size: 16px;
color: #232735;
}

.disclaimer2-div{
  display: flex;
  flex-direction: row;
  padding: 5px;
  background-color: #ffffff;
  border: 2px dashed #4072EE;
  margin-top: 0%;
  margin-bottom: 10%;
  border-radius: 3px;
}

.contact-message-height{
  height: 700px;
}

.modal1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.aligncenter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.normal-text-center-large{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  text-align:center;
  width: 70%;
  margin-bottom: 20px;
}


.modal1-button{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.col-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.disclaimer7{
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #ffffff;
  border: 2px dashed #4072EE;
  margin-top: 0%;
  margin-bottom: 10%;
  border-radius: 3px;
}

.disclaimer7-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #4D5785;
  padding-top: 3px;
  line-height: 2.3;
  padding-left: 15px;
  letter-spacing: .75px;
}

.disclaimer7-bold{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #4D5785;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 10px;


}

.width-tight{
  width: 300px;
}

.highlight-yellow{
  background-color: #ffff00;
  color: #ffff00;
}

.disclaimer2-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #4D5785;
  padding-top: 10px;
  line-height: 2.3;
  padding-left: 15px;
  letter-spacing: .75px;
}

.disclaimer2-img{
  width: 120px;
  height: 120px;
}

.disclaimer-bold{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #4D5785;
}

.back-button{
  width: 25px;
  height: 25px;
}

.public-nav{
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  padding-left: 15px;
}

.cpa-free-guidance-page{
  background-image: linear-gradient(#EFFBFF, #F9F7FF);
}

.blue-background{
  background-image: linear-gradient(#EFFBFF, #F9F7FF);
}

.cpa-detail-heading{
  font-family: ProductSansBold;
  font-size: 34px;
  color: #232735;
}

.cpa-detail-breadcrumb{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #697B8C;
}

.nav-link{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #232735;
  margin-right: 50px;
}

.phone-text-3{
  font-family: ProductSansBold;
  font-size: 14px;
  color: #232735;
  text-align: right;
}

/* Home */
.header-section{
  background-image: linear-gradient(#F5F5FF, #FFFFFF);
}

.text-tiny{
  font-family: ProductSansRegular;
  font-size: 13px;
  color: #697B8C;
}

.home-header{
  font-family: ProductSansBold;
  font-size: 47px;
  color: #232735;
  margin-bottom: 0;
}

.home-discription{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #4D5785;
  line-height: 2;
  width: 470px;
  margin-top: 0;
  margin-bottom: 50px;

}

.home-secondary-header{
  font-family: ProductSansBold;
  font-size: 20px;
  color: #232735;
  text-align: center;
  padding: 20px 20%;
}

.home-secondary-header-mini{

}

.home-image-one{
  width: 400px;
}

.bbb{
  width:180;
  height:50px;
  margin-top: 50px;
}



.underline-thick{
  width: 170px;
  height: 7px;
  background-color: #4D5785;
  color: #4D5785;
  border: 1px solid transparent;
  border-radius: 8px;
}

.underline-thick2{
  width: 130px;
  height: 7px;
  background-color: #4D5785;
  color: #4D5785;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 43%;
}

.center-between{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  padding: 15px 70px;


}

.admin-filemanager-left{
  margin-left: 20%;
}

.center-content{
  padding: 0 50px;
}

.center-content-comms{
  padding: 0 15%;
  background-color: white;
  padding-bottom: 30%;
}

.center-content-cpa{
  padding: 0 10%;
}

.center-content-disclaimer{
  padding: 0 20%;
}

.center-content-3{
  padding: 0 50px;
  border-bottom: 2.2px solid #E0EAFF;
  margin-bottom:20px;
}
.center-content-3-1{
  padding: 0 50px;
  margin-bottom:20px;
}

.features-section{
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}

.home-sub-section{
  font-family: ProductSansBold;
  font-size: 14px;
  color: #232735;
  margin: 0;
  padding: 0;
}

.home-sub-section-2{
  font-family: ProductSansBold;
  font-size: 14px;
  color: #538FFF;
  margin: 0;
  padding: 0;
}

.home-services-offered-div{
  display: flex;
  flex-direction: row;
}

.home-services-offered{
  border: 1px solid #E0EAFF;
  border-radius: 2px;
  padding: 10px 15px;
  width: 45%;
  height: auto;
  margin: 20px 20px;
}

.home-services-offered-heading{
  font-family: ProductSansBold;
  font-size: 15px;
  color: #232735;
  margin: 0;
  padding-top: 10px;

}

.home-services-offered-details{
  font-family: ProductSansRegular;
  font-size: 13px;
  color: #232735;
  margin: 0;

}

.home-services-offered-img{
  width:70px;
  height:70px;
  margin-left:10px;
}

.about-us-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-us-img{
  width: 200px;
  height: 200px;
  margin: auto;
  margin-top: 40px;
}

.about-us-name{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #232735;
  text-align: center;
  margin-bottom: 0;

}

.about-us-title{
  font-family: ProductSansItalic;
  font-size: 14px;
  color: #232735;
  text-align: center;
  margin-top: 0;

}

.about-us-para{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #232735;
  line-height: 2.5;
  text-align: center;
  padding: 0 13%;
}
.about-us-right-tab{
	color: #232735;
	font-family: 'ProductSansRegular';
	font-size: 14px;
	line-height: 2.5;
	text-align: center;
}

.background-img1{
  /* width: 100%;
  height: 30%; */
}

.public-heading{
  font-family: ProductSansBold;
  font-size: 32px;
  text-align: center;
}

.client-sign-div{
  padding: 10% 10%;
}


.why-it-matters-background-img{
  height: 500px;
  padding-top: 10%;
  background-image: url("./Images/shop.png");
  background-repeat: no-repeat;
}

.why-it-matters-sub-title{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #697B8C;
}

.why-it-matters-title{
  font-family: ProductSansBold;
  font-size: 24px;
  color: #232735;
  line-height: 1.4;
}

.why-it-matters-para{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #232735;
  line-height: 2;
  margin-bottom: 50px;
}

.why-it-matters-white{
  margin-left: 40%;
  background-color: white;
  width:30%;
  height:100%;
  padding: 10px 30px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.button-none{
  background-color: transparent;
  border: none;
}

.green-button{
  background-color: #009B60;
  color: white;
  font-family: ProductSansBold;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
  width: 500px;
}

.blue-button-short{
  background-color: #538FFF;
  color: white;
  font-family: ProductSansBold;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 6px;
  width: 300px;
}

.orange-button-short{
  background-color: #F39C0B;
  color: white;
  font-family: ProductSansBold;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 6px;
  width: 300px;
}

.features{
  padding: 4% 4%;
}

.features-img-left{
  padding: 4% 0 4% 4%;
}

.features-img-right{
  padding: 4% 4% 4% 4%;

}

.features-img{
  width: 35%;
  height: 100%;
}

.features-img-2{
  width: 30%;
  height: 100%;
}

.features-bottom{
  display: flex;
  flex-direction: row;
  margin-bottom: 8%;
}
.features-bottom-2{
  display: flex;
  flex-direction: row;
  margin-bottom: 0%;
}
.gradient-blue{
  background-image: linear-gradient(#EFFBFF, #F9F7FF);

}

.gradient-pink{
  background-image: linear-gradient(#FFF3F1, #F9F7FF);

}

.payment-img{
  margin-right: 20px;
  margin-bottom: 25px;
}

.header-section-half{
  padding-left: 50px;
}

.headers-heading{
  font-family: ProductSansRegular;
  font-size: 30px;
  color: #232735;
  line-height: 2;
  width: 80%;
  margin: 0;
}

.headers-details{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  line-height: 2.5;
  width: 80%;
}

.headers-details-2{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  line-height: 2.5;
  width: 80%;
}

.faq-img{
  width: 50px;
  height: 50px;

}

.faq-blue-border{
  border: 2.2px solid #E0EAFF;
  border-radius: 3px;
  margin: 0 5%
}

.faq-heading{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #232735;
  line-height: 2;
  width: 80%;
}

.faq-details{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #232735;
  line-height: 2;
  width: 80%;
}

.calendly-widget{
  font-family: ProductSansBold;
  font-size: 14px;
  background-color: white;
  color: #4072EE;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid #DBDBDB;
  border-radius: 20px;

}

.form-label{
  font-family: ProductSansBold;
  font-size: 14px;
  color: #232735;
  margin-bottom: 10px;
}

.cpafg-form-input{
  padding: 10px;
  font-family: ProductSansRegular;
  font-size: 16px;
  border: 1px solid #DBDBDB;
  color: black;
  margin: 0 30%;
  width: 50%;
}

.form-input{
  padding: 10px;
  font-family: ProductSansRegular;
  font-size: 16px;
  border: 1px solid #DBDBDB;
  color: black;
}

.contact-div{
  margin-top: 50px;
}

.contact-button{
  font-family: ProductSansBold;
  font-size: 14px;
  background-color: white;
  color: white;
  background-color: #4072EE;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 5px;
  float: right;
  margin-top: 10px;
}

.contact-map{
  width:400px;
}

.contact-map2{
  width:300px;
}

.app{
  height: 100%;
}

html{
  height: 100%;
}

label{
  font-family: ProductSansBold;
  font-size: 12px;
  color: #232735;
}

.float-right{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 50% 0 50%;
}

.input-field{
  font-family: ProductSansRegular;
  font-size: 16px;
  width: 150px;
  padding: 10px;
  border: 1px solid #DBDBDB;
}

.input-field-new{
  font-family: ProductSansRegular;
  font-size: 16px;
  width: 150px;
  padding: 10px;
  border: 1px solid #DBDBDB;
  border-radius: 3px;
  width: 80%;
  height:20px;
}

.input-heading{
  font-family: ProductSansBold;
  font-size: 14px;
}

.emailResponse{
  font-family: ProductSansBold;
  font-size: 17px;
  color: #009B60
}

.emailBadResponse{
  font-family: ProductSansBold;
  font-size: 14px;
  color: red;
}

.pending{
  font-family: ProductSansBold;
  font-size: 14px;
  color: yellow;
}



.spacebetween{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bold{
  font-family: ProductSansBold;
  font-size: 24px;
  text-align: center;
}

.text-center-bold{
  font-family: ProductSansBold;
  text-align: center;
  font-size: 24px;
}

.text-center{
  font-family: ProductSansRegular;
  text-align: center;
  font-size: 24px;
}

.sideText{
  font-family: ProductSansRegular;
  color: #232735;
  font-size: 14px;
}

.sideText-large{
  font-family: ProductSansBold;
  color: #232735;
  font-size: 14px;
}

.black{
  color: #232735;
}

.dark-blue{
  color: #4072EE;
}

.light-blue{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #538FFF;
  background-color: white;
}

.pricetool-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 20px 20px;
}

.pricetool-other-footer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 50px;
}

.footer{
  z-index:99;
  position:relative;
  left:0;
  right:0;
  bottom:0;

}

.footer-div-quip{
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.underline-footer{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #4072EE;
  z-index:99;
  text-align: center;
  margin-bottom:25px;
  text-decoration: underline;
}

.footer-quip{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: black;
  z-index:99;
  text-align: center;
  margin-bottom:25px;
}

.filter-button-div{
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

/* .filter-button{
  font-family: ProductSansRegular;
  font-size: 14px;
  background-color: #4072EE;
  padding: 4px 15px;
  color: white;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 3px;
  margin-right: 10px;
} */

.row-1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
}

.row-2{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.row-space-between{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 0 21%; /* explanation below */
}

.row-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right:  100px;
}

.row-between-0{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right:  100px;
  align-items:baseline;
}

/* .filter-button-selected, .filter-button-selected:hover, .filter-button-selected:active, .filter-button-selected:focus{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #4072EE;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: Transparent;
 background-repeat:no-repeat;
 border: none;
 cursor:pointer;
 overflow: hidden;
 outline:none;
} */

.client-activity-div-select-location{
  display: flex;
  flex-direction: column;
  width: 80%;
  height:100px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  margin-left: 10%;
  margin-top: 3%;
}

.client-activity-div-select-location-div{
  display:'flex';
  align-items:"center";
}

.active-filter-button {
  color: white;
  font-family: ProductSansRegular;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: #618DF7;
 background-repeat:no-repeat;
 border: none;
 cursor:pointer;
 overflow: hidden;
 outline:none;
 padding:4px;
}

.active-filter-button:active{
  font-family: ProductSansRegular;
  font-size: 15px;
  color: white;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: #618DF7;
 background-repeat:no-repeat;
 border: none;
 cursor:pointer;
 overflow: hidden;
 outline:none;
 padding:4px;
}

.active-filter-button:focus{
  font-family: ProductSansRegular;
  font-size: 15px;
  color: white;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: #618DF7;
 background-repeat:no-repeat;
 border: none;
 cursor:pointer;
 overflow: hidden;
 outline:none;
 padding:4px;
}

.inactive-filter-button{
  font-family: ProductSansRegular;
  font-size: 15px;
  color: #232735;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: transparent;
 background-repeat:no-repeat;
 border: none;
 cursor:pointer;
 overflow: hidden;
 outline:none;
 padding:4px;
}
.inactive-filter-button:hover{
  font-family: ProductSansRegular;
  font-size: 15px;
  color: #618DF7;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: transparent;
 background-repeat:no-repeat;
 border: none;
 cursor:pointer;
 overflow: hidden;
 outline:none;
 padding:4px;
}



/* .filter-button{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #232735;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: #4072EE;
 background-repeat:no-repeat;
 border: none;
 cursor:pointer;
 overflow: hidden;
 outline:none;
} */

/* .filter-button:hover{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #4072EE;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: Transparent;
     background-repeat:no-repeat;
     border: none;
     cursor:pointer;
     overflow: hidden;
     outline:none;
} */

/* .filter-button:active {
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #4072EE;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: Transparent;
     background-repeat:no-repeat;
     border: none;
     cursor:pointer;
     overflow: hidden;
     outline:none;
} */

/* .filter-button:focus {
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #4072EE;
  text-align: center;
  text-decoration: none;
  margin-right: 10px;
  background-color: Transparent;
     background-repeat:no-repeat;
     border: none;
     cursor:pointer;
     overflow: hidden;
     outline:none;
} */

.button-filled{
  font-family: ProductSansBold;
  font-size: 16px;
  background-color: #4072EE;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.button-grey{
  font-family: ProductSansBold;
  font-size: 16px;
  background-color: #d4dbda;
  color: grey;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.button-filled-home{
  font-family: ProductSansBold;
  font-size: 16px;
  background-color: #4072EE;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
  margin-left: 20px;
}

.button-filled:active{
  font-family: ProductSansBold;
  font-size: 16px;
  background-color: #4072EE;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.button-filled:focus{
  font-family: ProductSansBold;
  font-size: 16px;
  background-color: #4072EE;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.button-outlined{
  font-family: ProductSansRegular;
  font-size: 16px;
  background-color: white;
  color: #697B8C;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid #697B8C;
  border-radius: 6px;
}

.button-outlined-home{
  font-family: ProductSansRegular;
  font-size: 16px;
  background-color: white;
  color: #697B8C;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid #697B8C;
  border-radius: 6px;
  margin-left: 50px;
}



.button-outlined:active{
  font-family: ProductSansRegular;
  font-size: 16px;
  background-color: white;
  color: #697B8C;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid #697B8C;
  border-radius: 6px;
}

.button-outlined:focus{
  font-family: ProductSansRegular;
  font-size: 16px;
  background-color: white;
  color: #697B8C;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid #697B8C;
  border-radius: 6px;
}

.button-disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.priceTool-div{
  padding:30px;
}

.priceTool-div-text{
  font-family: ProductSansRegular;
  font-size: 24px;
  color: #232735;
  letter-spacing: 1.8;
  line-height: 1.8;
}

.price-tool-sub-header{
  font-family: ProductSansItalic;
  font-size: 16px;
  color:#538FFF;
  margin-top: -15px;
  margin-bottom: 55px;
  text-align: center;
  padding: 0px 31%;
}

.margin-top-50{
  margin-top: 50px;
}

.confirmation-img{
  width: 30%;
  height: 30%;
  margin: 0 35%;
}

.confirmation-heading{
  font-family: ProductSansBold;
  font-size: 20px;
  color:#232735;
  text-align: center;
}

.confirmation-text{
  font-family: ProductSansRegular;
  font-size: 18px;
  color:#697B8C;
  text-align: center;
  padding-left:30%;
  padding-right:30%;
  margin-bottom: 3%;

}



.free-guidance-category-img{
  width: 100px;
  height: 100px;
  margin: 0 10%;
}

.row-space{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cpa-free-guidance-section{
	display: grid;
 	grid-template-columns: repeat(3,250px);
	justify-content: space-between;
}

.cpa-free-guidance-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.free-guidance-category-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  background-color: white;
  border: 1px solid transparent;
  width: 250px;
  height: 250px;
  margin-bottom: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  align-items: center;
}

.free-guidance-category-div:active{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  background-color: white;
  border: 1px solid transparent;
  width: 250px;
  height: 250px;
  margin-bottom: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  align-items: center;
}

.free-guidance-category-button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.free-guidance-category-text{
  font-family: ProductSansBold;
  font-size: 16px;
  color:#232735;
  text-align: center;
  width: 100%;
  padding: 0 20px;
  /* margin-left: 40px */
}

.cpafg-content{
margin: 0 13%;
}

.gold-disclaimer{
  font-family: "ProductSansItalic";
  font-size: 18px;
  color:#F39C0B;
  margin-top: -13px;
}


.blue-disclaimer{
  font-family: "ProductSansItalic";
  font-size: 24px;
  color:#4072EE;
}

.accordian{
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  margin-bottom: 20px;
  padding: 15px 20px;
  cursor: pointer;


}

.accoridan-button{
  cursor: pointer;
}

.accoridan-button:focus{
  outline:0;
}

.accoridan-button:active{
  outline:0;
}

.accoridan-heading:focus{
  outline:0;
}

.accoridan:focus{
  outline:0;
}

.accordian-heading{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #538FFF;
  cursor: pointer;
}

.accordian-heading-contact{
  font-family: ProductSansBold;
  font-size: 32px;
  color: #538FFF;
  cursor: pointer;
}

.accordian-heading:hover{

}

.terms-heading{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #538FFF;
}

.terms-text{
  font-family: ProductSansRegular;
  font-size: 15px;
  color: #232735;
  line-height: 2;
  letter-spacing: .72px;
}

.accordian-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  line-height: 2;
  letter-spacing: .72px;
}

.timeline{
  width: 60%;
  height: 60%;
  margin-left: 20%;
  margin-bottom: 35px;
  margin-top: 0px;
}

.disclaimer9-div{
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  border: 1px dashed #4072EE;
  background-color: #F6F9FF;
  margin-bottom: 30px;
  width: 70%;
  margin-left: 15%;
}

.disclaimer9-heading{
  font-family: ProductSansBold;
  font-size: 16px;
}

.disclaimer9-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  line-height: 1.8;
  margin-top: 0;
}

.disclaimer-one-div{
  border: 2px solid #4072EE;
  border-radius: 8px;
  background-color: #FFFFFF;
  width: 50%;

}

.disclaimer-one-text{
  font-family: ProductSansRegular;
  font-size: 14px;
  line-height: 1.8;
  width: 500px;
}

.disclaimer-one-img{
  width: 150px;
  height: 150px;
}


.hide{
  display:none;
}

.box-shadow{
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
}

.box-shadow-heavy{
  box-shadow: 0px 2px 50px rgba(35, 39, 53, .20);
}

.default-link{
  text-decoration: none;
  color: black;
}

.default-link:link{
  text-decoration: none;
  color: black;
}

.normal-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
}

.phone-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  margin-bottom: 10px;
  margin-top: 10px;
}

.phone-text-3{
  font-family: ProductSansRegular;
  font-size: 18px;
  color: #4072ee;
  margin-bottom: 10px;
  margin-top: 10px;
}

.phone-text-2{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #232735;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;

}

.normal-text-blue{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #538FFF;
}

.normal-text-center{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  text-align: center;
}

.client-activity-footer-text{
  font-family: ProductSansRegular;
  font-size: 20px;
  color: #232735;
  text-align: center;
  padding: 10px 20%;
}

.client-activity-footer-link{
  font-family: ProductSansRegular;
  font-size: 20px;
  color: #4072EE;
  text-decoration: none;
  padding: 10px 1%;
  text-align: center;

}

.normal-text-cpa{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  text-align: center;
}

.normal-text-cpa-bold{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #232735;
  text-align: center;

}

.normal-text-grey{
  font-family: "ProductSansRegular";
  font-size: 18px;
  color: #697B8C;
  text-align: center;
}

.normal-text-grey2{
  font-family: "ProductSansRegular";
  font-size: 15px;
  color: #697B8C;
  text-align: left;
}

.normal-text-center-large{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  text-align:center;
}

.blue-disclaimer-text{
  font-family: ProductSansItalic;
  font-size: 20px;
  color: #538FFF;
  width: 70%;
}

.step-option{
  width: auto;
  height: auto;
  padding: 10px;
  border: 1px solid #A7A7A7;
}

.step-option:active{
  width: auto;
  height: auto;
  padding: 10px;
  border: 2px solid #4072EE;
}

.serviceInactive-img{
  width: 80px;
  height:80px;
}

.image-text-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.steps-heading{
  font-family: "ProductSansBold";
  font-size: 24px;
  color: #232735;
  text-align: center;
  margin-bottom: 30px;
}

.steps-heading-minor{
  font-family: "ProductSansRegular";
  font-size: 24px;
  color: #232735;
  text-align: center;
}

.price-tool-step-one-option-inactive{
  padding: 10px 0px;
  border: 1px solid #A7A7A7;
  border-radius: 5px;
  margin-left: 10px;
  width: 150px;
  height:150px;
}



.select-tax-prep-div{
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  border: 1px solid #A7A7A7;
  border-radius: 5px;
  margin-left: 30px;
  width: 320px;
  height: 500px;
}

.select-tax-prep-div:hover{
  background: #ffffff;
  border: 2px solid #538FFF
}

.select-tax-prep-div:active{
  background: #ffffff;
  border: 2px solid #538FFF
}

.select-tax-prep-div:focus{
  background: #ffffff;
  border: 2px solid #538FFF
}

.price-tool-step-one-option-text{
  width: 100%;
  font-family: ProductSansBold;
  color: #91939A;
}

.service-grid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 30px;
}

.blue-circle{
  width:30px;
  height:30px;
  border: 1px solid transparent;
  border-radius: 50px;
  background-color: #538FFF;
}



.select-tax-prep-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  text-align: left;
  margin-bottom: 30px;
}

.price-tool-step-one-option-inactive:hover,
.price-tool-step-one-option-inactive:focus,
.price-tool-step-one-option-inactive:active {
    background: #ffffff;
    border: 2px solid #538FFF
}



.price-range-compact-heading{
  font-family: ProductSansBold;
  font-size: 18px;
  color:#4072EE;
}

.price-range-compact-text{
  font-family: ProductSansRegular;
  font-size: 18px;
  color:#697B8C;
}

.price-range-compact-seemore{
  font-family: ProductSansItalic;
  font-size: 12px;
  color:#697B8C;
}

.price-range-compact-div{
  /* width:100%; */
  /* height: 100%; */
}

.breadcrumb{
  /* all: unset; */
  border:none;
  color: black;
  font-family: ProductSansRegular;
  font-size: 18px;
}

.expanded-div{
  padding: 20px 40px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.expanded-back-button{
  font-family: ProductSansRegular;
  color:#697B8C;
  margin: 50px 0 15px 0;
  border: none;
}

.expanded-back-button:active{
  font-family: ProductSansRegular;
  color:#697B8C;
  margin: 50px 0 15px 0;
  border: none;
  background-color: #ffffff
}

.expanded-back-button:focus{
  font-family: ProductSansRegular;
  color:#697B8C;
  margin: 50px 0 15px 0;
  border: none;
  background-color: #ffffff
}

.modal2-div{
  margin: 80px 0 15px 0;
  padding-top: 20px;
  padding-bottom: 100px;

}

.button-no-style{
  border: none;
}

.button-no-style:focus{
  border: none;
  background-color: #ffffff
}

.button-no-style:active{
  border: none;
  background-color: #ffffff
}

.expanded-heading{
  font-family: ProductSansBold;
  font-size: 38px;
  color:#4072EE;
  text-align: center;
}

.expanded-heading2{
  font-family: ProductSansBold;
  font-size: 38px;
  color:#232735;
  text-align: center;
}

.expanded-text{
  font-family: ProductSansRegular;
  font-size: 19px;
  color:#323C47;
  line-height: 3;
}

.button-link{
  font-family: ProductSansRegular;
  font-size: 19px;
  color:blue;
  line-height: 3;
  border: none;
  background-color: white;
}

.button-link:active{
  font-family: ProductSansRegular;
  font-size: 19px;
  color:blue;
  line-height: 3;
  border: none;
  background-color: white;
}

.expanded-text-0{
  font-family: ProductSansRegular;
  text-align: center;
  font-size: 19px;
  color:#323C47;
  line-height: 1.8;
  margin-bottom: 30px;
}

.compact{
  width: 45%;
  margin-left: 2%;
  margin-bottom: 3%;
  padding: 5px 20px;
  background-color: #ffffff;
  cursor: pointer;

}

.compact:focus{
  width: 45%;
  margin-left: 2%;
  margin-bottom: 3%;
  padding: 5px 20px;
  background-color: #ffffff
}

.compact:active{
  width: 45%;
  margin-left: 2%;
  margin-bottom: 3%;
  padding: 5px 20px;
  background-color: #ffffff
}

.priceTool-individual-step3-div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px;
}


.col{
  display: flex;
  flex-direction: column;
}

.col-quickview{
  display: flex;
  flex-direction: column;
  width: inherit;
}

.row{
  display: flex;
  flex-direction: row;
}
.row-all{
	display: flex;
	flex-direction: row;
	margin-left: -.75rem;
	margin-right: -.75rem;
	margin-bottom: 20px;
}

.fileDivContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  border: 1px dashed #4072EE;
}

.fileDiv{
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-around;
  border: 2px solid #F2F2F2;
  width: 200px;
  height: 200px;

}

.fileIcon{
  display: flex;
  justify-content: center;
  overflow: hidden;

}

.fileName{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #323C47;
  text-align: center;

}

.requiredDocumentsText{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #323C47;
  margin-top: 3px;
  margin-left: 3px;
  width: 80%;

}

.check-circle{
  width: 30px;
  height: 30px;
}

.required-documents-checklist{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.what-happens-next-heading{
  font-family: ProductSansBold;
  font-size: 22px;
  color: #323C47;
}

.what-happens-next-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #323C47;
  margin-top: 0;
  margin-left: 3px;
}

.what-happens-next-text-2{
  font-family: ProductSansItalic;
  font-size: 16px;
  color: #538FFF;
  width: 70%;
  line-height: 1.8;
}

.what-happens-next-img{
    width:20px;
    height:20px;
}
.what-happens-next-bitmoji{
  width:200px;
  height:200px;
}

.half{
  width: 50%;
}

.sign-img{
  width: 100%;
  height: 112%;
}

.sign-img2{
  width: 100%;
  height: 80%;
}

.contact-section{
  width: 50%;
}

.margin-bottom-30{
  margin-bottom: 30px;
}

.margin-left-20{
  margin-left: 20px;
}

.screen-padding{
  padding: 50px;
}
.requiredDocumentsDiv{
    /* width: 100%;
     margin: 0 auto;
     padding: 20px; */
}

.row-center{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row-center-top-20{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.row-contact{
  margin-top:40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.client-tax-dashboard-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 15% 15px 0;
  margin-left: 15%;
}

.client-tax-dashboard-row-0{
  display: flex;
  flex-direction: row;
  margin-left: 25px;
  margin-bottom: 50px;
}

.client-tax-dashboard-row-1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10%;

}

.client-tax-dashboard-initals-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #4D5785;
  padding: 0 13px;
  height: 80%;
  margin-right: 10px;
}

.client-tax-dashboard-name{
  font-family: ProductSansBold;
  font-size: 40px;
  color: #232735;
  padding: 0;
  margin: 0;
}

.client-tax-dashboard-type{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #232735;
  padding: 0;
  margin: 0;
}

.client-tax-dashboard-initals{
  font-family: ProductSansBold;
  font-size: 40px;
  color: white;
  padding: 0 30px;
}

.quickview-client{
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 2px dashed #4072EE;
  width: inherit;
  height: 50%;
  border-radius: 3px;
}

.quickview{
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 2px dashed #4072EE;
  width: 55%;
  height: 50%;
  border-radius: 3px;
}

.quickview-1{
  display: flex;
  flex-direction: row;
}

.quickview-text{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  margin: 6px;
}

.select-location-heading{
  font-family: ProductSansBold;
  font-size: 22px;
  color:#232735;
  text-align: center;
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
}

.bold2{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #232735;
  margin: 6px;
}

.bold3{
  font-family: ProductSansBold;
  font-size: 16px;
  margin: 6px;
  text-decoration: underline;
}

.bold4{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #232735;
  margin-top: 20px;
}

.admin-file-manager{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10%;
}

.page-half{
}

.padding-bottom-lg{
  padding-bottom: 300px;
}

.sheets-label{
  font-family: ProductSansBold;
  font-size: 14px;
  color: #232735;
}

.sheets-label2{
  font-family: ProductSansBold;
  font-size: 12px;
  color: #4072EE;
}

.admin-file-manager-label{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #232735;
}

.admin-file-manager-label2{
  font-family: ProductSansBold;
  font-size: 20px;
  text-align: center;
  padding: 22% 20% 0 0;
  color: #232735;
}

.margin-left-20{
  margin-top: 20px;
  margin-left: 20px;
}

.client-taxReturn-div{
  padding: 10px;
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  border: 1px solid transparent;
  border-radius: 3px;
  width: 270px;
  height: 130px;
  margin: 20px 0 50px 20px;
}

.admin-archive-taxReturn-div{
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  border: 1px solid transparent;
  border-radius: 3px;
  width: 270px;
  height: 80px;
  margin: 20px 0 50px 20px;
}

.admin-taxreturn-img-2{
  width: 40px;
  height: 40px;
}

.client-taxReturn-div-img{
  width: 60px;
  height: 50px;
}

.client-taxReturn-div-service{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #0E1B42;
}

.client-nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

.admin-dashboard{
  width: 100%;
  height: 300%;
  background-color: #F6F9FF;
}

.admin-nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

.admin-nav-detail{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
}

.client-nav-detail{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
}

.client-nav-detail-2{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;
}



.admin-graph-div{
  display: flex;
  flex-direction: row;
}

.admin-graph-title{
  font-family: ProductSansRegular;
  font-size: 20px;
  color: #4D5785;

}


.admin-graph-value{
  font-family: ProductSansBold;
  font-size: 24px;
  color: #212145;
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.admin-graph{
  width: 300px;
  height: 100px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  margin: 0 20px;
  padding: 13px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
}

.admin-taxreturn-div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  height:100%;
  padding: 10px 30px 0 0;

}

.admin-taxreturn{
  width: 10%;
  height: 250px;
  border: 1px solid transparent;
  border-radius: 5px;
  margin: 10px 60px;
  padding: 20px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
}

.admin-taxreturn-img{
  width: 50px;
  height: 50px;
}

.admin-taxreturn-name{
  font-family: ProductSansBold;
  font-size: 12px;
  color: #0E1B42;
  margin-bottom: 0;
  padding-bottom: 0;
}

.admin-taxreturn-name{
  font-family: ProductSansRegular;
  font-size: 12px;
  color: #8186AD;
  margin-top: 0;
  padding-top: 0;
}

.admin-nav-name{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #273142;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-right: 20px;
}

.admin-nav-role{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #9CB2CD;
  margin-top: 0;
  padding-top: 0;
  margin-right: 20px;

}

.admin-taxreturn{
  width: 50px;
  height: 50px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
}


.admin-workflow-section{
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100%;
  padding: 10px 15%;
}

.admin-workflow-div-0{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  background-color: white;
  border-bottom: 2px solid #E0EAFF;
  padding: 40px 40px;
}

.admin-workflow-heading{
  font-family: ProductSansBold;
  font-size: 22px;
  color: #0E1B42;
}

.admin-workflow-initals-div{
  margin: 0px;
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 8px;
  width: 70px;
  height: 50px;
}

.admin-workflow-initals-text{
  margin: 0px;
  padding: 0px;
}

.admin-workflow-name{
  margin: 0px;
  padding: 0px;
}

.admin-workflow-role{
  font-family: ProductSansRegular;
  margin: 0px;
  padding: 0px;
  text-align: left;
}

.admin-workflow-input{
  display: flex;
  flex-direction: column;
  width: 150px;
}

.margin-bottom-30{
  padding-bottom: 30px;
}

.admin-workflow-action{
  font-family: ProductSansBold;
  margin: 0px;
  padding: 0px;
  text-align: left;
  /* display: flex; */
  justify-content: flex-start;

}

.admin-workflow-timestamp{
  margin: 0px;
  padding: 0px;
}

.admin-workflow-status-pending{
  font-family: ProductSansBold;
  color: #F5A623;
  margin: 0px;
  padding: 0px;
}

.admin-workflow-status-complete{
  font-family: ProductSansBold;
  color: #11B469;
  margin: 0px;
  padding: 0px;
}



.delete-button-client{
  background-color: transparent;
  border: none;
  color: red;
  margin-left: 20px;
  padding: 0;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
}

.delete-button-client:active{
  background-color: transparent;
  border: none;
  color: red;
  margin-left: 20px;
  padding: 0;
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;

}

.delete-button{
  background-color: transparent;
  border: none;
  color: red;
  margin-left: 20px;
  padding: 0;
  width: 100%;
}

.delete-button:active{
  background-color: transparent;
  border: none;
  color: red;
  margin-left: 20px;
  padding: 0;
  width: 100%;
}


.delete-button-2{
  background-color: transparent;
  border: none;
  color: red;
  float: right;
  text-align: right;
  padding: 0;
  width: 100%;
}

.archiveTaxYear{
  font-family: ProductSansBold;
  font-size: 16px;
}


.archiveTaxYearTitle{
  font-family: ProductSansRegular;
  font-size: 12px;
}

.archive-status{
  background-color: #F4360E;
  border: 1px solid transparent;
  font-family: ProductSansBold;
  font-size: 13px;
  color: white;
  padding: 5px 10px;
  width: 80px;
  border-radius: 3px;
  margin-top: 10px;

}

.archive-button{
  background-color: #F4360E;
  text-align: left;
  color: #F4360E;
  border: 1px solid transparent;
  font-family: ProductSansBold;
  font-size: 12px;
  color: white;
  padding: 5px 10px;
  width: 170px;
  height: auto;
  float:right;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
}

.archive-link{
  font-family: ProductSansRegular;
  font-size: 12px;
  color: blue;
  padding-bottom: 10px;
}

.archive-text{
  background-color: transparent;
  color: red;
  border: none;
  font-family: ProductSansBold;
  font-size: 12px;
  padding: 5px 10px;
  width: auto;
  height: auto;
  float:right;
}

DropdownButton{
  background-color: red !important;
  color: red !important;

}

.admin-button{
  font-family: ProductSansBold;
  font-size: 12px;
  background-color: #4072EE;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.admin-button:focus{
  font-family: ProductSansBold;
  font-size: 12px;
  background-color: #4072EE;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.admin-button2{
  font-family: ProductSansBold;
  font-size: 12px;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.admin-button-pending{
  font-family: ProductSansBold;
  font-size: 12px;
  background-color: #F5A623;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.admin-button-complete{
  font-family: ProductSansBold;
  font-size: 12px;
  background-color: #11B469;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.admin-button-negative{
  font-family: ProductSansBold;
  font-size: 12px;
  background-color: red;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  border: 1px solid transparent;
  border-radius: 6px;
}

.logo{
  width:250px;
  height:40px;
}

.logo2{
  width:250px;
  height:40px;
}

.client-nav-logo{
  float: left;
}

.client-nav-name{
  font-family: ProductSansRegular;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.client-nav-type{
  font-family: ProductSansRegular;
  font-size: 16px;
  margin: 0;
  padding: 0;

}

.client-nav-img{
  width: 50px;
  height: 50px;

}

.client-button-filled{
  font-family: ProductSansBold;
  font-size: 16px;
  background-color: #4072EE;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 3px 10px;
  border: 1px solid transparent;
  border-radius: 0px;
  height: 35px;
  border-radius: 3px;
  float:right;
}

.client-tax-dashboard-section1{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0;
}

.client-tax-dashboard-section2{
  width: 100%;
  height: 100%;
  background-color: white;
  margin: 0;
}

.client-tax-dashboard-nav-div{
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  /* padding-bottom: 5px; */
  padding: 15px 60px;
}

.client-tax-dashboard-nav-div-1{
  /* justify-content:flex-start; */
  /* padding-bottom: 5px; */
  padding: 15px 60px 15px 10%;
}

.admin-tax-dashboard-nav-div{
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  padding-bottom: 5px;
  margin-left: 15%;
}

.client-tax-dashboard-nav-link{
  text-decoration: none;
  border: none;
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #538FFF;
  margin-right: 8px;
  background-color: transparent;
}

.archive-file-link{
  text-decoration: none;
  border: none;
  font-family: ProductSansRegular;
  font-size: 14px;
  text-decoration: underline;
  color: #538FFF;
  margin-right: 8px;
  background-color: transparent;
  text-align: justify;
  margin-top: 10px;
}

.archive-file-link:active{
  text-decoration: none;
  border: none;
  font-family: ProductSansRegular;
  font-size: 14px;
  text-decoration: underline;
  color: #538FFF;
  margin-right: 8px;
  background-color: transparent;
  text-align: justify;
  margin-top: 10px;
}
.archive-file-link:hover{
  text-decoration: none;
  border: none;
  font-family: ProductSansRegular;
  font-size: 14px;
  text-decoration: underline;
  color: #538FFF;
  margin-right: 8px;
  background-color: transparent;
  text-align: justify;
  margin-top: 10px;
}

.archive-file-link:focus{
  text-decoration: none;
  border: none;
  font-family: ProductSansRegular;
  font-size: 14px;
  text-decoration: underline;
  color: #538FFF;
  margin-right: 8px;
  background-color: transparent;
  text-align: justify;
  margin-top: 10px;
}

.margin-right-10{
  margin-right: 30px;
}

.margin-right{
  margin-right: 10%;
  width: 200px;
  background-color: #11B469;
}

.client-activity-div-0{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height:110px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  margin-left: 10%;
}

.client-activity-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height:110px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  margin-left: 10%;
  margin-top: 3%;
}

.client-activity-div-invoice-agreement{
  display: flex;
  flex-direction: column;
  width: 80%;
  height:140px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  margin-left: 10%;
  margin-top: 3%;
}

.flex-start{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.client-activity-div2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.client-activity-div3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;
  padding: 0;

}

.activity-heading{
  font-family: ProductSansBold;
  font-size: 16px;
  color: #232735
}

.activity-sub-heading{
  font-family: ProductSansRegular;
  font-size: 16px;
  color: #697B8C;
}

.activity-button-outlined{
  font-family: ProductSansRegular;
  font-size: 20px;
  background-color: white;
  color: #4072EE;
  text-align: center;
  text-decoration: none;
  padding: 10px 35px;
  height: 50px;
  border: 2px solid #4072EE;
  border-radius: 6px;
  margin-top: 1.5%;
}


.activity-double-button-1{
  font-family: ProductSansRegular;
  font-size: 18px;
  background-color: white;
  color: #697B8C;
  border: 1px solid #697B8C;
  width: 200px;
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
  margin-top: 7.5%;

}


.activity-double-button-2{
  font-family: ProductSansBold;
  font-size: 18px;
  background-color: #4072EE;
  color: white;
  border: 2px solid #4072EE;
  width: 120px;
  height: 50px;
  border-radius: 5px;
  margin-top: 7.5%;
}


.activity-double-button-1-agreement{
  font-family: ProductSansRegular;
  font-size: 18px;
  background-color: white;
  color: #697B8C;
  border: 1px solid #697B8C;
  width: 200px;
  height: 50px;
  margin-right: 10px;
  border-radius: 5px;
  margin-top: 15px;
}


.activity-double-button-2-agreement{
  font-family: ProductSansBold;
  font-size: 18px;
  background-color: #4072EE;
  color: white;
  border: 2px solid #4072EE;
  width: 120px;
  height: 50px;
  border-radius: 5px;
  margin-top: 15px;
}


.background-color{
  background-color: #F6F9FF;
}

.background-color-white{
  background-color: white;
}

.admin-files-div{
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 0;
  margin-top: 20px;
}

.dashboard-file-link{
  padding: 0;
  margin: 0;

}

.client-activity-disclaimer{
  border: 1px dashed #4072EE;
  background-color: #F6F9FF;
  width:300px;
}

.client-activity-disclaimer-text{
  font-family: ProductSansRegular;
  font-size: 14px;
  color: #4D5785;
  margin: 10px 15px 30px 15px;

}

.client-activity-stripe-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height:200px;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
  margin-left: 10%;
  margin-top: 3%;
}

.blog-list-container{
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  align-content: center;
  align-self: center;
}

.blog-list-col{
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.blog-list-div{
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  margin: 30px 0;
}

.blog-list-thumbnail{
  width: 20%;
  height: 20%;

}

.blog-list-title{
  font-family: ProductSansBold;
  font-size: 24px;
  color:#232735;
}

.blog-list-text{
  font-family: ProductSansRegular;
  font-size: 14px;
  color:#232735;
}

.blog-list-content{
  font-family: ProductSansItalic;
  font-size: 14px;
  color:#232735;
}

.blog-article-content{
  font-family: ProductSansRegular;
  font-size: 16px;
  line-height: 1.8;
  color:#232735;
}

.blog-article-arthor{
  font-family: ProductSansBold;
  font-size: 16px;
  color:#232735;
}

.blog-article-date{
  font-family: ProductSansItalic;
  font-size: 16px;
  color:#232735;
}

.blog-article-page{
    padding: 0 10%;
}

.blog-article-pic{
  width: 50%;
  height: 10%;
  align-self: center;

}

.center-image{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
}


.text-padding{
  padding: 5px 0;
}

.welcome-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome-text1{
  font-weight: ProductSansBold;
  font-size: 24px;
  color: #538FFF;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 15px;

}

.welcome-text2{
  font-weight: ProductSansRegular;
  font-size: 16px;
  color: #232735;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 15px;

}

.welcome-img{
  width: 20%;
  height: 20%;
  margin-left: 50%;

}

.welcome-button{
  font-weight: ProductSansBold;
  font-size: 20px;
  padding: 8px 3px;
  background-color: #538FFF;
  color: white;
  border: none;
  border-radius: 3px;
  width: 130px;
  margin-top: 15px;
  text-align: center;
  margin-left: 44%;
}


.stripe-button{
  font-weight: ProductSansBold;
  font-size: 20px;
  padding: 8px 3px;
  background-color: #6772e5;
  color: white;
  border: none;
  border-radius: 3px;
  width: 50%;
  margin-top: 15px;

}

.stripe-heading{
  font-weight: ProductSansBold;
  font-size: 20px;
}

.stripe-checkout-div{
  padding-bottom: 100px;
}

.contact-div-0{
  display: flex;
  flex-direction: column;
}

.contact-div-1{
  display: flex;
  flex-direction: column;
}

.contact-div-2{
  display: flex;
  flex-direction: column;
}

.contact-div-half{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
}

.home-secondary-header-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

}

.search-input-container{
	display: inline-flex;
	width: 100%;
	position: relative;
	border-radius: 12px;
	background-color: white;
	box-shadow: 0px 2px 11px 0px lightgray;
	margin-bottom: 37px;
	padding: 16px 0px 18px 19px;
}

.search-icon{
	width: 20px;
	height: 20px;
	margin-right: 10px;
}

.search-input{
	margin: 0px !important;
	height: 20px !important;
	border-bottom: none !important;
  	-webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.search-match-list{
    position: absolute;
    width: 100%;
    left: 0;
	top: 67px;
	background-color: white;
	overflow: hidden;
	border-radius: 12px;
	box-shadow: 0px 2px 11px 0px lightgray;
	max-height: 430px;
    overflow-y: scroll;
}
.search-match-item{
	border-bottom: 1px solid rgba(202, 202, 202, 0.59);
	padding: 10px 20px 10px 20px;
	cursor: pointer;
}
.search-match-item:hover{
	background-color: lightgray;
}

.about-us-bottom-section{
	padding: 0 15%;
	display: flex;
	justify-content: space-around;
	margin-top: 50px;
}
.about-us-secition-data{
	width: 45%;
}

@media screen and (max-width: 1200px) {


  .disclaimer9-div{
    width: 100%;
    margin: 20px 0;
  }

  Container{display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

  .col-6{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .compact{
    width: 100%;
  }

  .service-grid{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .price-tool-step-one-option-inactive{
    margin-bottom: 25px;
    background-color: white;

  }

  .select-tax-prep-div{
    padding: 0;
    margin: 30px 0 0 0;
    background-color: white;
  }

  .timeline{
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .cpa-free-guidance-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .cpa-free-guidance-section{
	display: grid;
	grid-template-columns: repeat(3,1fr);
  }

  .free-guidance-category-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    background-color: white;
    border: 1px solid transparent;
    width: 100%;
    height: 150px;
    margin-bottom: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
    align-items: center;
  }

  .free-guidance-category-text{
    font-family: ProductSansBold;
    font-size: 16px;
    color:#232735;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    /* margin-left: 40px */
  }


  .free-guidance-category-img{
    width: 50px;
    height: 50px;
    margin: 0 10%;
  }

  .home-img-right{
    display:none
  }



  .row{
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .why-it-matters-white{
    width: 80%;
    margin: 0;
  }

  .features-bottom{
    display: flex;
    flex-direction: column;
    margin-bottom: 8%;
  }

  .contact-div{
    display: flex;
    flex-direction: column;
    margin: 50px 20px;
    padding: 0;
  }

  .mobile-col{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .header-section-half{
    width: 100%;
    margin: 50px 10px;
    padding: 0;
  }

  .client-activity-div, .client-activity-div-0, .client-nav, .client-nav-detail, .client-tax-dashboard-row , .quickview-1, .quickview, .client-activity-stripe-div{
    display: flex;
    flex-direction: column;
  }


  .client-activity-div, .client-activity-div-0, .client-nav, .client-nav-detail, .client-tax-dashboard-row , .client-activity-stripe-div{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .admin-file-manager{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .admin-file-manager-label-div{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .mobile-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }


  .contact-div-0{
    display: flex;
    flex-direction: column;
  }

  .contact-div-1{
    display: flex;
    flex-direction: column;
  }

  .contact-div-2{
    display: flex;
    flex-direction: column;
  }

  .contact-div-half{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 50px;
  }

  .features-img{
	  height: 0px;
	  flex:1;
  }

  .features-img-2{
	height: 0px;
	flex: 1;
  }
}



@media screen and (max-width: 500px) {

  .mobile-only{
    display: block;
  }

  .disclaimer2-div{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .cpa-free-guidance-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cpa-free-guidance-section{
    display: flex;
    flex-direction: column;
  }

  .free-guidance-category-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    background-color: white;
    border: 1px solid transparent;
    width: 100%;
    height: 150px;
    margin-bottom: 50px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, .15);
    align-items: center;
  }

  .free-guidance-category-text{
    font-family: ProductSansBold;
    font-size: 16px;
    color:#232735;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    /* margin-left: 40px */
  }


  .free-guidance-category-img{
    width: 50px;
    height: 50px;
    margin: 0 10%;
  }


  .header-section-half{
    width: 100%;
    margin: 50px 0px;
    padding: 0;
  }

  .mobile-banner-row{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }



  .button-filled-home{
    font-family: ProductSansBold;
    font-size: 14;
    background-color: #4072EE;
    color: white;
    text-align: center;
    text-decoration: none;
    padding: 10px 15px;
    border: 1px solid transparent;
    border-radius: 6px;
    margin-left: 20px;
  }

  .button-outlined-home{
    font-family: ProductSansRegular;
    font-size: 14px;
    background-color: white;
    color: #697B8C;
    text-align: center;
    text-decoration: none;
    border: 1px solid #697B8C;
    border-radius: 6px;
    margin-left: 0;
  }


  .home-discription{
    width: 100%;
    text-align: center;

  }

  .home-header{
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .half{
    width: 100%;
  }

  .contact-section{
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    padding: 0 0px;
  }

  .center-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-quip{
    padding: 0 30px
  }

  .calendly-widget{
    margin-bottom: 50px;

  }

  .home-secondary-header{
    padding: 0 10px;
  }

  .mobile-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

.home-services-offered-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-services-offered{
  margin: 15px 2px;
}

.home-services-offered-details{
  width: 100%;
}

.about-us-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.about-us-img{
  padding: 0;
  margin: 20px 0 0 0;
}


.why-it-matters-white{
  height: 95%;
}

.features-bottom{
  display: flex;
  flex-direction: column;
  padding-left: 0px;
}

.center-col{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center-content{
	width: 100%;
}

.center-content-3{
  padding: 0 20px 0 20px;
}

.center-content-3-1{
  padding: 0 20px 0 20px;
}

.faq-details{
  width: 100%;
}

.features-img , .features-img-right , .features-img-left{
  display: none;
}

.public-nav{
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 15px;
}

.nav-link{
  margin: 0;
  padding: 0;
  width: 100%;
  text-align:center;
  margin-top: 5px;
}


.contact-div-0{
  display: flex;
  flex-direction: column;
}

.contact-div-1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.contact-div-2{
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.contact-div-half{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 50px;
}

.admin-tax-dashboard-nav-div{
	margin: 5px;
	flex-direction: column;
}
.client-tax-dashboard-nav-link{
	text-align: center;
}
.admin-nav{
	flex-direction: column;
}
.admin-workflow-heading{
	margin: 25px 25px 0px 25px;
	text-align: center;
}
.admin-workflow-section{
	padding: 10px 5px;
}
.admin-workflow-div-0{
	padding: 40px 20px;
}
.about-us-bottom-section{
	flex-direction: column;
}
.about-us-secition-data{
	width: 100%;
}

.d3-div{
	padding-top: 50px;
}

.d4-div{
	display: inline;
	text-align: center;
}
.d4-text{
	padding: 10px;
	text-align: center;
}

.timeline{
	padding: 20px;
}
.steps-heading{
	padding:20px;
}
.select-tax-prep-div{
	margin: auto;
	margin-bottom: 20px;
	padding: 20px;
	height: fit-content;
}

.double-disclaimer{
	flex-direction: column;
}
.d5-div2{
	margin: 0px;
	padding:10px;
	width: 100%;
}
.center-content-comms{
	padding: 20px;
}
.quickview{
	width: 100%;
}

}
